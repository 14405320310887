import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Aadimaon`}</strong>{` is a religion primarily practiced in `}<a parentName="p" {...{
        "href": "/Tharsis",
        "title": "Tharsis"
      }}>{`Tharsis`}</a>{` and other countries in the surrounding region in northern `}<a parentName="p" {...{
        "href": "/Selar",
        "title": "Selar"
      }}>{`Selar`}</a>{`. Aadimaon is a polytheistic religion built upon various indegenous religions throughtout the region that held veneration for the `}<a parentName="p" {...{
        "href": "/Primal%20Spirits",
        "title": "Primal Spirits"
      }}>{`Primal Spirits`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      